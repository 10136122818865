/* TitleBar.css */
.title-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    background-color: #4A90E2;
    color: white;
    position: relative;
}

.logo {
    height: 40px;
    position: absolute;
    left: 10px;
}

.title-text {
    flex-grow: 1;
    text-align: center;
}
