.reply-bar {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
}

.message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
}

/* ReplyBar.css */
.message.user {
    background-color: #f8f9fa; /* Light grey background */
    text-align: right;
    width: 80%; /* Smaller width */
    margin-left: auto; /* Right align */
}

.message.ai {
    background-color: white;
    text-align: left;
    width: 100%;
}